import React, { useState, useEffect } from 'react';
import { getAccessToken } from '../middlewares/accessToken';
import { vendorsdataApi, paymentupdatesApi, paymenthistoryApi } from '../api/allapi';
import './dashboard.css';
import { Button } from '@material-ui/core';
import VendorCard from './vendorCard';
import Navbar from './navbar';
import { Paper, Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import Footer from './footer';
import Setpin from './setpin';
import CustomAlert from "./alert";
import { formatDateTime } from './formatDate';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import _ from 'lodash';
// import Loading from './loading';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import DummySkeleton from './DummySkeleton';
import { isMobile } from './constExport';


const Home = () => {
  const [vendors, setVendors] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [AlertRes, setAlertRes] = useState({ status: null, msg: null });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState("");
  // const [AlertRes, setAlertRes] = useState({ status: 200, msg: "Working" });
  const { token, roleslist, setpin } = getAccessToken();
  const [noPayment, setNoPayment] = useState(null);

  useEffect(() => {
    if (!token || !roleslist) {
      window.location.href = '/login';
    } else if (roleslist && roleslist.includes("Vendor")) {
      paymentupdatesApi()
        .then((response) => response.data)
        .then((data) => {
          if (data.length > 0) {
            // Assume the first row contains the column names
            const columnNames = Object.keys(data[0]);
            // Create the columns array dynamically based on the keys in the first row
            // console.log("Column:", columnNames);
            const columns = columnNames.map((columnName) => ({
              field: columnName,
              // Format the header name (replace underscores with spaces)
              headerName: columnName.replace('_', ' ')
                // .toLowerCase() // Convert the whole string to lowercase
                .split(' ') // Split the string into an array of words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                .join(' '), // Join the words back into a string
              // headerAlign: 'center',
              headerClassName: 'payments-header',
              flex: isMobile ? 0 : ((columnName === "id" || columnName === "amount") ? 1 : 1.5),  // Adjust this based on the desired proportion for each column
              minWidth: 130, // Optional: Set a minimum width for the columns
              width: columnName === "PaymentAt" ? 180 :
                columnName === "user_name" ? 150 :
                  undefined, // Default width for any other column
            }));
            // Create a custom 'id' field for each row if it doesn't exist in the API response
            // const rowsWithDataId = data.map((row, index) => ({ ...row, id: index }));
            const formattedData = data.map((item) => ({
              ...item,
              PaymentAt: formatDateTime(item.PaymentAt)
            }));
            // console.log("Columns: ", columns);
            // console.log("Rows: ", formattedData);
            setColumns(columns);
            setRows(formattedData);
          }
          else {
            setNoPayment(true);
          }
        })
        .catch((error) => console.error('Error fetching data:', error));

      if (isMobile) {
        setColumnVisibilityModel({ id: false, status: false, comments: false });
      } else {
      }
      const intervalId = setInterval(() => {
        // Reload the page after every 10 seconds
        window.location.reload();
      }, 60000); // 10000 milliseconds = 10 seconds
      // Clear the interval when the component is unmounted or navigated away
      return () => clearInterval(intervalId);
    } else {
      vendorsdataApi()
        .then((response) => {
          setVendors(_.sortBy(response.data, 'user_name'))
          // console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [token, roleslist]);

  // Callback function to receive the result from the child
  const pinSetReturn = (data) => {
    setAlertRes({ status: data.status, msg: data.msg })
    setTimeout(() => {
      setAlertRes({ status: null, msg: null });
    }, 3000)
  };

  // Set the padding value based on the screen size
  const DataGridPaddingValue = isMobile ? '10px' : '20px';
  const DataGridMarginValue = isMobile ? 0 : 2;

  // date range calendar stuffs 👇🏻
  const currentDay = dayjs();
  const minDate = currentDay.subtract(2, 'months').startOf('month');
  const [startDate, setStartDate] = React.useState(currentDay);
  const [endDate, setEndDate] = React.useState(currentDay);

  const handleStartDateChange = (value) => {
    setStartDate(value || currentDay);
    if (endDate.isBefore(value)) {
      setEndDate(value);
    }
  }
  const handleEndDateChange = (value) => {
    // const selectedEndDate = value;
    setEndDate(() => {
      if (value.isBefore(startDate) || value.isSame(startDate, 'day')) {
        return startDate;
      } else {
        return value || currentDay;
      }
    });
  }

  const handleDateSubmit = () => {
    console.log('start and end date', startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
    generateAndDownloadPdf(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
  }

  const generateAndDownloadPdf = async (start, end) => {
    // Create a new jsPDF document with a specific page size and orientation
    const doc = new jsPDF({
      orientation: 'portrait', // or 'landscape'
      unit: 'mm', // units can be 'pt', 'mm', 'cm', 'in'
      format: 'a4' // standard page sizes include 'a3', 'a4', etc.
    });
    let { username } = getAccessToken();
    var data;
    var doc_name;
    await paymenthistoryApi(start, end)
      .then((response) => {
        if (response.data.length > 0) {
          data = response.data.map((item) => ({
            ...item,
            PaymentAt: formatDateTime(item.PaymentAt)
          }));
          // Parse the first and last objects' PaymentAt properties
          var firstPaymentDate = new Date(response.data[0].PaymentAt);
          // var lastPaymentDate = new Date(response.data[response.data.length - 1].PaymentAt);
          // Extract the month/ Year values
          // console.log("First Payment Month:", firstPaymentDate.getMonth() + 1); // +1 because months are 0-indexed
          // console.log("Last Payment Month:", lastPaymentDate.getMonth() + 1);
          if (start === end) {
            doc_name = `${username}_${new Date(start).getDate()}-${new Date(start).getMonth() + 1}-${new Date(start).getFullYear() % 100}`
          } else {
            // doc_name = `${username}_${firstPaymentDate.getDate()}-${(firstPaymentDate.getMonth() + 1)}_${lastPaymentDate.getDate()}-${(lastPaymentDate.getMonth() + 1)}`
            doc_name = `${username}_${firstPaymentDate.getDate()}-${(firstPaymentDate.getMonth() + 1)}-${firstPaymentDate.getFullYear() % 100}_${new Date(end).getDate()}-${new Date(end).getMonth() + 1}-${new Date(end).getFullYear() % 100}`;
          }
          // Define table headers
          const headers = ['ID', 'User Name', 'Status', 'Amount', 'Comments', 'Payment At'];
          // Define table data
          data = data.map(item => [
            item.id,
            item.user_name,
            item.status,
            item.amount,
            item.comments,
            item.PaymentAt
          ]);
          // Set the position for the table
          const tableX = 10;
          const tableY = 20; // Adjust the vertical position for the table
          // Add a "Welcome" header
          doc.setFontSize(16);
          doc.text(`${username}`, 105, 10, 'center');
          // Add the table with autotable
          doc.autoTable({
            head: [headers],
            body: data,
            startY: tableY,
            styles: { halign: 'center' },
            columnStyles: { 5: { columnWidth: 'auto' } },
            margin: { left: tableX },
            tableWidth: 'auto'
          });
          // Calculate the Grand Total
          const grandTotal = data.reduce((sum, subarray) => {
            const amount = subarray[3];
            if (typeof amount === 'number') {
              return sum + amount;
            }
            return sum;
          }, 0);
          // const grandTotal = await data.reduce((total, item) => total + item.amount, 0);
          // Add the Grand Total immediately below the table
          const finalY = doc.lastAutoTable.finalY;
          doc.setFontSize(14);
          // Add the "Grand Total" text to the PDF with right alignment
          doc.text(`Grand Total: Rs ${grandTotal.toFixed(2)}`, 210 - doc.getStringUnitWidth(`Grand Total: Rs ${grandTotal.toFixed(2)}`) * 6.4, finalY + 10);
          // Save the PDF
          doc.save(doc_name);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };



  return (
    <div className='dashboard-parent-container'>
      <Navbar />
      {
        setpin ? (
          <Setpin onResult={pinSetReturn} />
        ) : (
          <>
            {roleslist && (roleslist.includes("User") || roleslist.includes("Admin")) && !roleslist.includes("Vendor") &&
              <>
                {/* <Box style={{ position: 'absolute', width: '100%', height: '50vh', overflow: 'hidden', zIndex: '-3' }}>
                  <img style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' }} src={HeroImage} alt='HomePage Background' />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#ffff',
                      mixBlendMode: 'multiply', // Apply a blending mode to enhance darkness
                      backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 1))'
                    }}
                  ></div>
                </Box > */}
                <Box className="vendor-box-container" sx={{ zIndex: -2 }}>
                  <Paper sx={{ display: 'flex', flexDirection: 'column', gap: '30px', padding: '20px', backgroundColor: "#FAFAFA", marginTop: '50px' }}>
                    {/* <DummySkeleton/> */}
                    {vendors.map((vendor) => (
                      <VendorCard key={vendor.user_name} name={vendor.user_name} vendorid={vendor.user_id} vendortype={vendor.user_type} img={vendor.bg_url} />
                    ))}
                  </Paper>
                </Box>
              </>
            }
            {
              roleslist && roleslist.includes("Vendor") &&
              <div style={{ minHeight: '100vh', width: '100%', paddingTop: '50px', backgroundColor: '#F5F7F8' }}>
                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px 10px 15px 15px', gap: '5px', backgroundColor: '#F5F7F8' }}>
                  {/* <Button size='small' variant='contained' color='primary' style={{
                        backgroundColor: "#0C356A"
                      }} onClick={() => { generateAndDownloadPdf('daily') }}>Current Month Bill</Button>
                      <Button size='small' variant='contained' color='primary' style={{
                        backgroundColor: '#0C356A'
                      }} onClick={() => { generateAndDownloadPdf('history') }}>Last 3 Months Bill</Button> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker label="Start Date" value={startDate} maxDate={currentDay} minDate={minDate} onChange={handleStartDateChange} />
                      <DatePicker label="End Date" value={endDate} maxDate={currentDay} minDate={startDate} onChange={handleEndDateChange} />
                      <Button variant='contained' color='primary' onClick={handleDateSubmit}>Download Report</Button>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                </LocalizationProvider>
                {noPayment ? (<h1>No Payments</h1>) : (
                  <DataGrid
                    sx={{ m: DataGridMarginValue, padding: DataGridPaddingValue, backgroundColor: 'white', border: '0.5px solid #B4B4B3', height: 'fit-content', mt: '5' }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 100 },
                      },
                    }}
                    // loading={loading}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setColumnVisibilityModel(newModel)
                    }
                    getCellClassName={() => 'payments-row'} // Apply the custom class to row
                  />
                )}
              </div>
            }
          </>
        )
      }
      <div className="alert-container-dashboard">
        {AlertRes.status && (
          <CustomAlert
            severity={AlertRes.status === 200 ? 'success' : 'error'}
            msg={AlertRes.status === 200 ? AlertRes.msg : AlertRes.msg}
          />
        )}
      </div>
      <div style={{ marginTop: 'auto' }}>
        <Footer />
      </div>
    </div>
  );
};

export default Home;