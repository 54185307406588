import React, { useState, useEffect } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Footer from './footer';
import Navbar from './navbar';
import dayjs from 'dayjs';
import { vendorhistoryApi } from '../api/allapi';
import { formatDate, formatTime, UTCTimestamp } from './formatDate';
import ExportToExcel from './excel-download';
import CustomAlert from "./alert";
import { isMobile } from './constExport';
import { getAccessToken } from '../middlewares/accessToken';
import { useNavigate } from 'react-router-dom';


export default function BasicDatePicker() {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [Export, setExport] = useState([]);
    const [AlertRes, setAlertRes] = useState({ status: null, msg: null });
    // date range calendar stuffs 👇🏻
    const currentDay = dayjs();
    const minDate = currentDay.subtract(6, 'months').startOf('month');
    const [startDate, setStartDate] = React.useState(currentDay);
    const [endDate, setEndDate] = React.useState(currentDay);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState("");


    useEffect(() => {
        const { roleslist } = getAccessToken();
        if (!(roleslist.includes("Admin") || roleslist.includes("Finance") || roleslist.includes("Super Admin"))) {
            navigate("/");
        }
    }, [navigate]);

    const handleStartDateChange = (value) => {
        setStartDate(value || currentDay);
        if (endDate.isBefore(value)) {
            setEndDate(value);
        }
        setColumns([]);
        setRows([]);
    }

    const handleEndDateChange = (value) => {
        // const selectedEndDate = value;
        setEndDate(() => {
            if (value.isBefore(startDate) || value.isSame(startDate, 'day')) {
                return startDate;
            } else {
                return value || currentDay;
            }
        });
        setColumns([]);
        setRows([]);
    }

    const handleDateSubmit = async () => {
        await vendorhistoryApi(UTCTimestamp(`${startDate.format("YYYY-MM-DD")}T00:00:00`), UTCTimestamp(`${endDate.format("YYYY-MM-DD")}T23:59:59`))
            .then((res) => {
                if (res.data.length > 0) {
                    const formattedData = res.data.map((item) => ({
                        id: item.Payment_ID,
                        User_Name: item.User_Name,
                        Vendor_Name: item.Vendor_Name,
                        Status: item.Status,
                        Amount: item.Amount,
                        Comments: item.Comments,
                        Date: formatDate(item.DateTime),
                        Time: formatTime(item.DateTime)
                    }));
                    setExport(res.data.map((item) => ({
                        Payment_ID: item.Payment_ID,
                        User_Name: item.User_Name,
                        Vendor_Name: item.Vendor_Name,
                        Status: item.Status,
                        Amount: item.Amount,
                        Comments: item.Comments,
                        Date: formatDate(item.DateTime),
                        Time: formatTime(item.DateTime)
                    })));

                    if (isMobile) {
                        setColumnVisibilityModel({ id: false, Status: false, Comments: false });
                    }
                    // Assume the first row contains the column names
                    const columnNames = Object.keys(formattedData[0]);
                    // Create the columns array dynamically based on the keys in the first row
                    const columns = columnNames.map((columnName) => ({
                        field: columnName,
                        // Format the header name (replace underscores with spaces)
                        headerName: columnName.replace('_', ' ')
                            // .toLowerCase() // Convert the whole string to lowercase
                            .split(' ') // Split the string into an array of words
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                            .join(' '), // Join the words back into a string
                        headerClassName: 'payments-header',
                        flex: isMobile ? 0 : ((columnName === "id" || columnName === "Amount") ? 1 : 1.5),  // Adjust this based on the desired proportion for each column
                        minWidth: 110, // Optional: Set a minimum width for the columns
                        width: (columnName === "User_Name" || columnName === "Vendor_Name") ? 170 :
                            columnName === "Amount" ? 130 :
                                columnName === "Status" ? 120 :
                                    // (columnName === "Date" || columnName === "Time") ? 110 :
                                    undefined, // Default width for any other column
                    }));
                    // console.log("Rows: ", formattedData);
                    // console.log("Columns: ", columns)
                    setColumns(columns);
                    setRows(formattedData);
                } else {
                    setAlertRes({ status: 302, msg: "No Payments" })
                    setColumns([]);
                    setRows([]);
                    setTimeout(() => {
                        setAlertRes({ status: null, msg: null });
                    }, 3000)
                }
            })
            .catch((err) => {
                setAlertRes({ status: err.response.status, msg: err.response.data.msg })
                setColumns([]);
                setRows([]);
                setTimeout(() => {
                    setAlertRes({ status: null, msg: null });
                }, 3000)
            })
    }

    return (
        <div style={{ minHeight: '100vh', width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: '#F5F7F8' }}>
            <div style={{ flex: '1', justifyContent: 'center', alignItems: 'center' }}>
                <Navbar />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ padding: '60px', maxWidth: '100%', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <div style={{ marginBottom: '10px' }}> {/* Adjust padding between components */}
                            <DatePicker label="Start Date" value={startDate} maxDate={currentDay} minDate={minDate} onChange={handleStartDateChange} />
                        </div>
                        <div style={{ marginBottom: '10px' }}> {/* Adjust padding between components */}
                            <DatePicker label="End Date" value={endDate} maxDate={currentDay} minDate={startDate} onChange={handleEndDateChange} />
                        </div>
                        <Button variant="contained" onClick={() => handleDateSubmit()} style={{ marginTop: '10px', marginBottom: '20px', textTransform: 'none' }}> {/* Adjust padding and margin */}
                            Submit
                        </Button>
                    </DemoContainer>
                    </div>
                </LocalizationProvider>
                {rows.length > 0 &&
                    <>
                        <div style={{ marginBottom: '10px', textAlign: 'left', marginLeft: '50px' }}>
                            <ExportToExcel
                                data={Export}
                                fileName={`CFA_Finance_${startDate.format("DD-MM-YY")}_${endDate.format("DD-MM-YY")}`}
                            />
                        </div>

                        {/* <Box sx={{ height: 400, width: '100%' }}> */}
                        <div style={{ height: 530, width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 100 },
                                    },
                                }}
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) =>
                                    setColumnVisibilityModel(newModel)
                                }
                                // checkboxSelection
                                columnBuffer={25}
                                sx={{
                                    boxShadow: 2,
                                    border: 2,
                                    width: "100%", // Make the DataGrid width 100%
                                    '& .MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#f0f0f0', // Optional: Style for header background
                                    }
                                }}
                            />

                        </div>
                        {/* </Box> */}
                    </>
                }
                {AlertRes.status && (
                    <CustomAlert
                        severity={AlertRes.status === 200 ? 'success' : 'error'}
                        msg={AlertRes.status === 200 ? AlertRes.msg : AlertRes.msg}
                    />
                )}
            </div>
            <div style={{ marginTop: 'auto' }}>
                <Footer />
            </div>
        </div>
    );
}