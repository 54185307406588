import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Email, Lock } from '@material-ui/icons';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import './login-signup.css';
import { Card, CardContent, Typography } from '@mui/material';
import { Microsoft } from '@mui/icons-material';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../middlewares/ssoAuth";
import { loginApi, ssologinApi } from '../api/allapi';
import { getAccessToken, setAccessToken } from '../middlewares/accessToken';
import CustomAlert from './alert';
import Loading from './loading';
import PassReset from './passwordReset';


const Login = () => {
  const [useremail, setuseremail] = useState('');
  const [userpassword, setuserpassword] = useState('');
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [AlertRes, setAlertRes] = useState({ status: null, msg: null });
  const [InvalidAccount, setInvalidAccount] = useState(null);

  const [popperModelOpen, setPopperModelOpen] = useState(false)
  const userpasswordRef = useRef(null); // Create a ref for the userpassword TextField

  const handlessoLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.log('SSO login error:', e);
    })
  };

  const AlertTimer = (callback) => {
    setTimeout(() => {
      setAlertRes({ status: null, msg: null });
      if (callback) {
        callback(); // Execute the callback function
      }
    }, 2000);
  };

  // useEffect(() => {
  //   const handleRedirect = async () => {
  //     try {
  //       const ssoAccessToken = await instance.handleRedirectPromise();
  //       if (ssoAccessToken) {
  //         // Pass the accessToken to your backend API
  //         ssologinApi(ssoAccessToken.accessToken).then(accessToken => {
  //           // Store the accessToken in localStorage
  //           setAccessToken(JSON.stringify(accessToken.data));
  //           // Redirect to the home page
  //           window.location.href = '/'
  //         }).catch(error => {
  //           // Handle any errors that occur during the userinfo request
  //           console.log("Backend login error: ", error);
  //         });
  //       };
  //     } catch (error) {
  //       console.log('SSO redirect error:', error);
  //     }
  //   };

  //   handleRedirect();
  // }, []);

  useEffect(() => {
    if (accounts[0]) {
      try {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        }).then((ssoAccessToken) => {
          if (ssoAccessToken) {
            // Pass the accessToken to your backend API
            ssologinApi(ssoAccessToken.accessToken).then(accessToken => {
              // Store the accessToken in localStorage
              setAccessToken(accessToken.data);
              // Redirect to the home page
              window.location.href = '/'
            }).catch(error => {
              console.log('SSO redirect error:', error.response.data.error);
              setInvalidAccount(error.response.data.error);
              localStorage.clear();
              sessionStorage.clear();
            })
          }
        });
      } catch (error) {
        console.log('SSO redirect error:', error);
      }
    }
    else if (getAccessToken().token) {
      navigate("/")
    }
  }, [accounts, instance, navigate]);

  const handleLogin = async () => {
    if (useremail === '' || userpassword === '') {
      // Show an error message or prevent form submission here
      setAlertRes({ status: 400, msg: 'Please fill in both fields' });
      AlertTimer();
      return;
    }
    else {
      const isfsemail = (useremail) => {
        const emailRegex =
          /^[a-zA-Z0-9._%+-]+@(afreespace|workplacefabric)\.com$/;
        return emailRegex.test(useremail);
      };
      if (isfsemail(useremail)) {
        setAlertRes({ status: 401, msg: "Please Login Using SSO" });
      }
      // Make API request to login endpoint
      loginApi(useremail, userpassword).then(response => {
        // Handle successful login
        console.log('Login Success');
        // Store the accessToken in localStorage
        setAccessToken(response.data);
        setAlertRes({ status: response.status, msg: response.data.msg });
        AlertTimer(() => {
          navigate('/');  // Redirect to home page or dashboard
        });
      }).catch(error => {
        // Handle login error
        if (isfsemail(useremail)) {
          setAlertRes({ status: 401, msg: "Please Login Using SSO" });
        } else {
          setAlertRes({ status: error.response.status, msg: error.response.data.error });
        }
        setuseremail('');
        setuserpassword('');
        console.error('Login failed:', error);
        AlertTimer();
      });
    }
  };

  const handleForgotPassword = () => {
    setPopperModelOpen(true);
  };

  const handleClosePopper = () => {
    setPopperModelOpen(false);
  };

  return (
    <div>
      {InvalidAccount ? (
        <h1>{InvalidAccount}</h1>) : (
        <>
          {isAuthenticated ? <Loading txt={"Please wait while the data is being fetched."} minH={'100vh'} /> :
            <div style={{ marginTop: '20px' }}>

              {popperModelOpen ? (<PassReset text={"Reset Your Password"} open={popperModelOpen} onClose={handleClosePopper} />) : ""}

              {/* !--------------------------User Login--------------------------! */}

              <div className='login-logo' style={{ fontFamily: 'Work Sans', fontWeight: 'bolder' }}>
                <img src='https://login.afreespace.com/static/media/logo.ad743ca34a0cddec3ff2.png' alt='FS Logo' />
                <p style={{ fontSize: '12px', textAlign: 'center', marginBottom: '50px' }}>Food on Demand, Expenses in Command</p>
              </div>

              <div className='login-container'>
                <Card className='user-card'>
                  <Typography style={{ fontFamily: 'Work Sans', backgroundColor: '#000000', color: 'white', padding: '8px ' }}>
                    Freespace User
                  </Typography>
                  <CardContent style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <Typography>
                      <Button
                        id="login-btn"
                        size='small'
                        variant="outlined"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => handlessoLogin()}
                      >
                        <Microsoft style={{ marginRight: '5px' }} />
                        <span style={{ lineHeight: 'normal' }}>MICROSOFT LOGIN</span>
                      </Button>
                    </Typography>
                  </CardContent>
                </Card>

                {/* !--------------------------Vendor Login--------------------------! */}

                <Card>
                  <Typography style={{
                    fontFamily: 'Work Sans', backgroundColor: '#000000',
                    color: 'white', padding: '8px '
                  }}>Vendor Login</Typography>
                  <Typography>
                    <div className='login-form-container'>
                      <form style={{ padding: '15px 15px 0px 15px', height: 'fit-content' }}>
                        <div className='form-group'>
                          <div className='form-elements'>
                            <TextField
                              size='small'
                              className="textField"
                              id="outlined-basic-email"
                              label="useremail"
                              variant="outlined"
                              value={useremail}
                              onChange={(e) => {
                                setuseremail(e.target.value.trim())
                                setAlertRes({ status: null, msg: null })
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <Email style={{ color: '#526D82' }} />
                                  </InputAdornment>
                                )
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter' && useremail.length > 0) {
                                  event.preventDefault(); // Prevent form submission
                                  userpasswordRef.current.focus(); // Move focus to the userpassword TextField
                                }
                              }}
                              required
                            />
                          </div>

                          <div >
                            <TextField
                              size='small'
                              className="textField"
                              type='password'
                              id="outlined-basic-password"
                              label="userpassword"
                              variant="outlined"
                              inputRef={userpasswordRef} // Assign the ref to the userpassword TextField
                              value={userpassword}
                              onChange={(e) => setuserpassword(e.target.value.trim())}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <Lock style={{ color: '#526D82' }} />
                                  </InputAdornment>
                                )
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter' && userpassword.length > 0) {
                                  event.preventDefault(); // Prevent form submission
                                  handleLogin(); // Trigger the login function
                                }
                              }}
                              required
                            />
                          </div>
                          <div className='form-elements'>
                            <p style={{
                              fontSize: '12px',
                              textAlign: 'right',
                              color: 'black',
                              fontWeight: 'bold',
                              cursor: 'pointer'
                            }}
                              onClick={handleForgotPassword}>forgot password ?</p>
                          </div>
                          <div className='form-elements'>
                            <Button
                              style={{ width: '80%' }}
                              id="register-btn"
                              size='small'
                              variant="outlined"
                              onClick={() => handleLogin()}>Login</Button>
                          </div>
                        </div>
                        {AlertRes.status && (
                          <CustomAlert
                            severity={AlertRes.status === 200 ? 'success' : 'error'}
                            msg={AlertRes.status === 200 ? 'Login Success' : AlertRes.msg}
                          />
                        )}
                        <div className='horizontal-line'></div>
                        <div style={{ marginBottom: '15px' }}>
                          <Typography >
                            <Typography style={{ textAlign: 'center', fontSize: '12px', marginBottom: '3px', fontFamily: 'Work Sans', fontWeight: 'bolder' }}>New Vendor ?</Typography>
                            <Button component={Link} to="/signup"
                              style={{ width: '80%', }}
                              id="login-btn"
                              size='small'
                              variant="outlined">Sign Up
                            </Button>
                          </Typography>
                        </div>
                      </form>
                    </div>
                  </Typography>
                </Card>
              </div>
            </div>

          }
        </>
      )}
    </div >
  );
};

export default Login;
