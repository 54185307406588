import React, { useState, useEffect } from 'react';
import { CreditUpdateApi, getUsersDataApi } from '../api/allapi';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';
import './credits-management.css'
import { Button } from "@material-ui/core";
// import { Button } from '@mui/material';
import AccountsManagement from './accounts-management';
import CustomAlert from "./alert";
import { getAccessToken } from '../middlewares/accessToken';
import { useDispatch } from 'react-redux';
import { updateData } from '../Redux/ActionCreators';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formattedDate } from './formatDate';
import Footer from './footer';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const CreditsManagement = () => {
  const navigate = useNavigate();
  const [UsersForCredit, setUsersForCredit] = useState([]);
  const [SelectedCreditUpdate, setSelectedCreditUpdate] = useState(3750);
  const [SelectedRows, setSelectedRows] = useState([]);
  const [AlertRes, setAlertRes] = useState({ status: null, msg: null });

  const { userid, roleslist } = getAccessToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!roleslist.includes("Admin") || roleslist.includes("Vendor")) {
      navigate("/");
    }
    getUsersDataApi('usercredit')
      .then((response) =>
        setUsersForCredit(formattedDate(response.data, "updatedAt")))
      .catch((e) => console.log('Error: ', e));
  }, [roleslist, navigate]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const toggleRowSelection = (id) => {
    if (SelectedRows.includes(id)) {
      setSelectedRows(SelectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows([...SelectedRows, id]);
    }
  };

  const toggleAllSelection = () => {
    if (SelectedRows.length === UsersForCredit.length) {
      setSelectedRows([]);
    } else {
      const allIds = UsersForCredit.map(item => item.user_id);
      setSelectedRows(allIds);
    }
  };

  const isSelectedDisabled = SelectedRows.length === 0;

  // const renderCreditUpdate = (user) => {
  //   const handleCreditChange = (e) => {
  //     const updatedUsers = UsersForCredit.map(u => {
  //       if (u.user_email === user.user_email) {
  //         return { ...u, credit: Number(e.target.value) };
  //       }
  //       return u;
  //     });
  //     setUsersForCredit(updatedUsers);
  //   }
  //   return (
  //     <>
  //       <input
  //         key={user.user_email} // Unique key for each input
  //         type='number'
  //         // value={(UsersForCredit.find(u => u.user_email === user.user_email)).credit}
  //         value={user.credit}
  //         onChange={handleCreditChange}
  //       />
  //       <button onClick={() => handleCreditUpdate(user.user_email, user.credit)}>Update Credit</button >
  //     </>
  //   );
  // };

  // const handleCreditUpdate = async (email, credit) => {
  //   try {
  //     const userId = UsersForCredit.find(user => user.user_email === email).user_id;
  //     CreditUpdateApi('user', [{
  //       userid: userId,
  //       credit: credit,
  //     }])
  //       .then((response) => {
  //         if (response.status === 200) {
  //           setAlertRes({ status: response.status, msg: "Credit update successful! for " + UsersForCredit.find(user => user.user_email === email).user_name });
  //           const updatedUsers = UsersForCredit.map(user => {
  //             if (user.user_email === email) {
  //               return { ...user, credit: credit };
  //             }
  //             return user;
  //           });
  //           setUsersForCredit(updatedUsers);
  //           if (updatedUsers.find(item => item.user_id === userid)) {
  //             dispatch(updateData((updatedUsers.find(item => item.user_id === userid)).credit));
  //           }
  //         } else {
  //           setAlertRes({ status: response.status, msg: "Credit update failed for " + UsersForCredit.find(user => user.user_email === email).user_name });
  //         }
  //         handleAlertTimeout(false);
  //       })
  //       .catch((e) => {
  //         console.log('Error: ', e);
  //         setAlertRes({ status: e.response.status, msg: "Credit update failed for " + UsersForCredit.find(user => user.user_email === email).user_name });
  //       });
  //   }
  //   catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleSelectedCreditUpdate = () => {
    // Call your API with SelectedRows
    const data = SelectedRows.map(id => ({ userid: id, 'credit': SelectedCreditUpdate }));
    CreditUpdateApi('user', data)
      .then((response) => {
        if (response.status === 200) {
          if (data.length === 1) {
            setAlertRes({ status: response.status, msg: "Credit update successful! for " + UsersForCredit.find(user => user.user_id === data[0].userid).user_name });
          } else {
            setAlertRes({ status: response.status, msg: "Credit update successful! for Selected Users" });
          }
          const updatedUsers = UsersForCredit.map(user => {
            const updatedUser = data.find(u => u.userid === user.user_id);
            if (updatedUser) {
              return { ...user, credit: updatedUser.credit };
            }
            return user;
          });
          setUsersForCredit(updatedUsers);
          if (updatedUsers.find(item => item.user_id === userid)) {
            dispatch(updateData((updatedUsers.find(item => item.user_id === userid)).credit));
          }
        } else {
          if (data.length === 1) {
            setAlertRes({ status: response.status, msg: "Credit update failed for " + UsersForCredit.find(user => user.user_id === data[0].userid).user_name });
          } else {
            setAlertRes({ status: response.status, msg: "Credit update failed for Selected Users" });
          }
        }
        handleAlertTimeout(false);
      })
      .catch((e) => {
        console.log('Error: ', e);
        if (data.length === 1) {
          setAlertRes({ status: e.response.status, msg: "Credit update failed for " + UsersForCredit.find(user => user.user_id === data[0].userid).user_name });
        } else {
          setAlertRes({ status: e.response.status, msg: "Credit update failed for Selected Users" });
        }
      });
    // Reset SelectedRows, setSelectedCreditUpdate after successful upload
    setSelectedRows([]);
    // setSelectedCreditUpdate('')
  };

  const handleAlertTimeout = (refresh) => {
    setTimeout(() => {
      setAlertRes({ status: null, msg: null });
      if (refresh) {
        window.location.reload();
      }
    }, 3000)
  }

  const navigateBack = () => {
    navigate(-1);
  }

  const theme = useTheme();


  return (
    <div style={{ width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div style={{ flex: '1' }}>
        <Navbar />
        <div style={{ paddingTop: '60px' }}>
          <div className="alert-container">
            {AlertRes.status && (
              <CustomAlert
                severity={AlertRes.status === 200 ? 'success' : 'error'}
                msg={AlertRes.status === 200 ? AlertRes.msg : AlertRes.msg}
              />
            )}
          </div>
          <div>
            <div className="credit-manage-back-btn">
              <Button disableRipple
                size='small'
                variant="outlined"
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => navigateBack()}
              > Back </Button>
            </div>
            <TableContainer component={Paper}
              sx={{
                margin: 'auto',
                width: '80%', // Default width
                [theme.breakpoints.down('sm')]: { // Change width to 100% on mobile view
                  width: '100%'
                },
              }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      <input
                        type="checkbox"
                        checked={SelectedRows.length === UsersForCredit.length}
                        onChange={toggleAllSelection}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">ID</StyledTableCell>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">Location</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Credit</StyledTableCell>
                    <StyledTableCell align="center">Updated At</StyledTableCell>
                    {/* <StyledTableCell align="center">{isSelectedDisabled && "Action"}</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {UsersForCredit.map((user) => (
                    <StyledTableRow key={user.user_id}>
                      <StyledTableCell align="center">
                        <input
                          type="checkbox"
                          checked={SelectedRows.includes(user.user_id)}
                          onChange={() => toggleRowSelection(user.user_id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">{user.user_id}</StyledTableCell>
                      <StyledTableCell align="center">{user.user_name}</StyledTableCell>
                      <StyledTableCell align="center">{user.user_email}</StyledTableCell>
                      <StyledTableCell align="center">{user.user_location}</StyledTableCell>
                      <StyledTableCell align="center">{user.status}</StyledTableCell>
                      <StyledTableCell align="center">{user.credit}</StyledTableCell>
                      <StyledTableCell align="center">{user.updatedAt}</StyledTableCell>
                      {/* <StyledTableCell align="center">{isSelectedDisabled && renderCreditUpdate(user)}</StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="credit-updater">
              <div>
                <input
                  disabled={isSelectedDisabled}
                  pattern='^[^-]*$'
                  style={{ textAlign: 'center', margin: 'auto' }}
                  className='credit-update'
                  type="number"
                  value={SelectedCreditUpdate}
                  onChange={(e) => {
                    // !isSelectedDisabled && setSelectedCreditUpdate(e.target.value)
                    if (!isSelectedDisabled) {
                      // Ensure the value is not less than 0 or greater than 5000
                      setSelectedCreditUpdate(Math.min(5000, Math.max(0, (parseInt(e.target.value, 10) || 0))));
                    }
                  }}
                />
              </div>
              <div>
                <Button onClick={handleSelectedCreditUpdate} disabled={isSelectedDisabled}
                  variant='contained' size='small' style={{ backgroundColor: !isSelectedDisabled && '#12ba29' }}>
                  Update
                </Button>
              </div>
            </Box>
          </div>
          <div style={{ marginTop: '30px' }}>
            <AccountsManagement />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 'auto' }}>
        <Footer />
      </div>
    </div >
  )
};

export default CreditsManagement;
